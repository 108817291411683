import React, { createContext, useContext, useEffect, useState } from "react";
import { PushNotification } from "../components/PushNotification";
export const StateContext = createContext();

const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
};

const initialUserState = {
  token: null,
  image: null,
  exp: null,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Dark");
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") || "en"
  );
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [user, setUser] = useState(initialUserState);
  const [trackerID, setTrackID] = useState(
    localStorage.getItem("admin-trackerID") || false
  );
  const [notification, setNotifiction] = useState({
    type: null,
    message: null,
  });

  const [cron, setCron] = useState(null);

  const handleToken = (data) => {
    setUser(data);
    localStorage.setItem("refreshAdmin", data.refresh);
  };

  function isTokenExpired() {
    const tokenExpiration = new Date(user.exp * 1000).getTime();
    const currentTime = new Date().getTime();
    return currentTime > tokenExpiration;
  }

  const setLang = (v) => {
    setCurrentLang(v);
    localStorage.setItem("lang", v);
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };

  const handleNotification = ({ type, message }) => {
    clearTimeout(cron);
    const time = setTimeout(() => {
      setNotifiction({ type: null, message: null });
    }, 2000);
    setCron(time);
    setNotifiction({ type: type, message: message });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        handleNotification,
        currentMode,
        activeMenu,
        setTrackID,
        trackerID,
        user,
        setUser,
        handleToken,
        isTokenExpired,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        currentLang,
        setLang,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
      }}
    >
      {children}
      {notification.type && (
        <PushNotification
          type={notification.type}
          message={notification.message}
          onClick={() => setNotifiction({ type: "", message: "" })}
        />
      )}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
