import { useState, useEffect } from "react";
import { useGET } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useDashboard = () => {
  const [dashboard, setDashboard] = useState({});
  const { handleNotification, user, trackerID } = useStateContext();
  useEffect(() => {
    useGET("dashboard/", { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res.type == "success") setDashboard(res.data);
        if (res.type == "error") handleNotification(res);
      }
    );
  }, []);

  return { dashboard };
};

export const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user, handleNotification, trackerID } = useStateContext();

  useEffect(() => {
    useGET("vendor/analytics/orders", {
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      if (res.type == "success") setOrders(res.data);
      if (res.type == "error") handleNotification(res);
    });
  }, []);
  return { orders };
};
