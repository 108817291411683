import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDashboard, useOrders } from "../api/analytics";
import { BarChart } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { earningData } from "../data/dummy";
import { handleOrdersChartData } from "../utils";
import { SelectInput } from "../components/Input";
import { translator } from "../translation";

const DashboardCards = () => {
  const { currentLang } = useStateContext();
  const { dashboard } = useDashboard();
  return (
    <>
      {earningData.map((item) => (
        <div
          key={item.title}
          className="bg-white flex items-center justify-evenly h-40 dark:text-gray-200 dark:bg-secondary-dark-bg w-[300px] p-4 rounded-2xl "
        >
          <div className="flex-1 max-w-[100px]">
            <Link
              to={item.link}
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className="text-2xl w-16 flex items-center justify-center h-16 opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              {item.icon}
            </Link>
          </div>
          <div className="flex-1">
            <p>
              <span className="text-[22px] font-semibold">
                {item.render
                  ? item.render(dashboard[item.dataKey])
                  : dashboard[item.dataKey]}
              </span>
              <span className={`text-sm text-${item.pcColor} ml-2`}>
                {item.percentage}
              </span>
            </p>
            <p className="text-lg text-gray-400  mt-1">
              {item.title[currentLang]}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

const Chart = () => {
  const { currentLang } = useStateContext();
  const { orders } = useOrders();
  const [choiece, setChoice] = useState(0);

  const { totalPricesData: totalPricesByMonth, labels: months } =
    handleOrdersChartData(orders, "month");

  const { totalPricesData: totalPricesByYear, labels: years } =
    handleOrdersChartData(orders, "year");
  const { totalPricesData: totalPricesByUser, labels: users } =
    handleOrdersChartData(orders, "user");

  const { totalPricesData: totalPricesByProvince, labels: provinces } =
    handleOrdersChartData(orders, "province");

  const chartChoices = [
    {
      id: 0,
      title: translator.charts.totalPrice.choices[0],
      total: totalPricesByMonth,
      labels: months,
    },
    {
      id: 1,
      title: translator.charts.totalPrice.choices[1],
      total: totalPricesByYear,
      labels: years,
    },
    {
      id: 2,
      title: translator.charts.totalPrice.choices[2],
      total: totalPricesByUser,
      labels: users,
    },
    {
      id: 3,
      title: translator.charts.totalPrice.choices[3],
      total: totalPricesByProvince,
      labels: provinces,
    },
  ];

  return (
    <div className="flex flex-col dark:bg-secondary-dark-bg items-center max-sm:w-[400px] sm:w-[500px] md:w-[500px] px-8 py-8 rounded-md bg-white">
      <div className="flex items-center w-full mb-8 justify-between flex-wrap">
        <h1 className="whitespace-nowrap dark:text-white pt-2 font-bold text-2xl max-sm:text-[18px] md:text-[20px]">
          {translator.charts.totalPrice.title[currentLang]}
        </h1>
        <SelectInput
          values={chartChoices}
          item={"title"}
          onChange={(e) => setChoice(e.target.value)}
          className="max-w-[250px]"
        />
      </div>
      <BarChart
        chartData={{
          labels: chartChoices[choiece].labels,
          datasets: [
            {
              label: chartChoices[choiece].title,
              data: chartChoices[choiece].total,
            },
          ],
        }}
      />
    </div>
  );
};

const Dashboard = () => {
  return (
    <div className="mt-16 px-16">
      <div className="flex flex-wrap lg:flex-nowrap gap-6">
        <div className="flex flex-wrap justify-center gap-4">
          <DashboardCards />
        </div>
        <Chart />
      </div>
    </div>
  );
};

export default Dashboard;
