import { useState } from "react";
import { SearchIcon } from "./Icons";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";

export const Input = ({
  label,
  type,
  required = true,
  minLength = 8,
  onChange,
  value,
  className,
}) => {
  const handleOnChange = (e) => {
    if (type == "number") {
      if (`${parseFloat(e.target.value)}` == "NaN" && e.target.value != "") {
        e.target.className = e.target.className
          .replace("focus:border-blue-500", "focus:border-red-500")
          .replace("focus:ring-blue-200", "focus:ring-red-200");
      } else {
        e.target.className = e.target.className
          .replace("focus:border-red-500", "focus:border-blue-500")
          .replace("focus:ring-red-200", "focus:ring-blue-200");
        e.target.value = e.target.value != "" ? parseFloat(e.target.value) : "";
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div className="block">
      <label
        className="block dark:text-white text-md text-gray-700 font-bold mb-2"
        htmlFor="inpt"
      >
        {label}
      </label>
      <input
        id={label}
        required={required}
        minLength={minLength}
        onChange={(e) => handleOnChange(e)}
        value={value}
        defaultValue={"02-02-2023"}
        className={`${className} no-number-spin focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 appearance-none dark:text-white bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-tight`}
        type={type == "number" ? "text" : type}
        placeholder={label}
      />
    </div>
  );
};

export const SearchInput = ({ onChange }) => {
  const [keyword, setKeyword] = useState();
  const handleOnChange = (value) => {
    setKeyword(value);
    onChange(value);
  };
  const { currentLang } = useStateContext();
  return (
    <div className="flex items-center justify-between border-2 border-gray-400 py-1 rounded-md px-3">
      <input
        type="text"
        value={keyword}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={translator.shared.search[currentLang]}
        className="text-md dark:text-white dark:bg-secondary-dark-bg py-1 outline-none w-full"
      />
      <SearchIcon />
    </div>
  );
};

export const SelectInput = ({
  label,
  item,
  onChange,
  className,
  values,
  value = 0,
  key = "id",
}) => {
  const { currentLang } = useStateContext();
  return (
    <div
      className={`${className} relative inline-block w-[240px] max-[945px]:w-full`}
    >
      <label
        className="block dark:text-white text-md text-gray-700 font-bold mb-2"
        htmlFor="select"
      >
        {label}
      </label>
      <select
        defaultValue={value}
        onChange={(e) => onChange(e)}
        id="select"
        className="bg-transparent dark:text-white border w-full border-gray-300 rounded-md py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
      >
        {values.map((value, i) => (
          <option key={i} value={value[key]}>
            {value[item][currentLang]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const DragAndDrop = ({
  className,
  label,
  onChange,
  value,
  id,
  bgSize = "cover",
}) => {
  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImage(file);
  }

  function handleFileInputChange(event) {
    const file = event.target.files[0];
    handleImage(file);
  }

  function handleImage(file) {
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const dropzone = document.getElementById(`dropzone-${id}`);
        dropzone.style.backgroundImage = `url(${e.target.result})`;
        dropzone.style.backgroundSize = bgSize;
        dropzone.style.padding = "80px";
        onChange({ file: file, data: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  }

  const handleClick = () => {
    const inpt = document.getElementById(id);
    inpt.click();
  };

  return (
    <div
      onClick={() => handleClick()}
      className={`border-2 cursor-pointer border-dashed ${className} border-gray-400 dark:border-gray-300 flex items-center justify-center cursor-pointer`}
      id={`dropzone-${id}`}
      style={{
        backgroundImage: `url(${value})`,
        backgroundSize: bgSize,
      }}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e)}
    >
      <label htmlFor="file-upload" className="flex items-center justify-center">
        <span className="text-gray-800 dark:text-gray-200">{label}</span>
      </label>
      <input
        type="file"
        id={id}
        className="hidden"
        accept="image/*"
        onChange={(e) => handleFileInputChange(e)}
      />
    </div>
  );
};
