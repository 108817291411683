import { useState } from "react";
import { useLogin } from "../api/auth";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { user } = useStateContext();
  const navigate = useNavigate();
  if (user.access) navigate("/");
  const [auth, setAuth] = useState({ username: "", password: "" });
  const { handleLogin } = useLogin();
  return (
    <div className="w-full h-full flex items-center justify-center">
      <form className="flex flex-col gap-4 bg-gray-100 border border-gray-100 p-20">
        <label htmlFor="username">username</label>
        <input
          onChange={(e) => setAuth({ ...auth, username: e.target.value })}
          type="text"
          id="username"
          className="w-96 py-2"
        />
        <label htmlFor="pass">password</label>
        <input
          onChange={(e) => setAuth({ ...auth, password: e.target.value })}
          type="text"
          id="pass"
          className="w-96 py-2"
        />
        <button
          type="button"
          onClick={() => handleLogin(auth)}
          className="px-6 py-2 bg-blue-600 text-white"
        >
          login
        </button>
      </form>
    </div>
  );
}
