import { URL } from "../urls";
import { token } from "../token";

export const useGET = async (url, conf) => {
  const get = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Comercify-Owner": token,
    },
  };
  if (conf?.token) {
    get.headers["Authorization"] = conf?.token;
  }
  if (conf?.trackerID) {
    get.headers["X-Comercify-Visitor"] = conf?.trackerID;
  }
  const results = await fetch(`${URL}${url}`, get)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const usePOST = async (url, conf) => {
  const post = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Comercify-Owner": token,
    },
    body: JSON.stringify(conf?.data),
  };
  if (conf?.token) {
    post.headers["Authorization"] = conf?.token;
  }
  if (conf?.trackerID) {
    get.headers["X-Comercify-Visitor"] = conf?.trackerID;
  }
  if (conf?.form) {
    delete post.headers["Content-Type"];
    post.body = conf.form;
  }
  const results = await fetch(`${URL}${url}`, post)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const useUPDATE = async (url, conf) => {
  const PUT = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conf?.data),
  };
  if (conf?.token) {
    PUT.headers["Authorization"] = conf.token;
  }
  if (conf?.form) {
    delete PUT.headers["Content-Type"];
    PUT.body = conf.form;
  }

  const results = await fetch(`${URL}${url}`, PUT)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};

export const useDELETE = async (url, conf) => {
  const delete_ = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conf?.data),
  };
  if (conf?.token) {
    delete_.headers["Authorization"] = conf.token;
  }
  const results = await fetch(`${URL}${url}`, delete_)
    .then((response) => {
      if (!response.ok) {
        return {
          type: "error",
          message: "Network response was not ok",
        };
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        type: "error",
        message: error,
      };
    });
  return results;
};
